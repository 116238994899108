/** @jsx jsx */
import { jsx, Box, Heading, Text } from "theme-ui"
import { graphql } from "gatsby"
import Marquee from "react-fast-marquee"
import {
  PageFooter,
  ContentBlock,
  BlockHeading,
  HeaderSpacing,
} from "../components/page"
import { Grid, Flex, Container, Button } from "theme-ui"
import { Connection, PageRoot, ContentNode, SharpImage } from "../data/content"
import {
  useLocalisedContent,
  getOverallLatest,
  TileSplitter,
  TilePrimary,
  FeaturedListTile,
} from "../components/featured"
import {
  PrimaryFeatured,
  FeaturedList,
  FeaturedListItem,
  SecondaryFeatured,
  TertiaryFeatured,
  SecondaryFeaturedLarge,
  LatestPrimaryFeatured,
  LatestFeaturedListItem,
} from "../components/featured"
import { InstagramModule } from "../components/insta"
import { Link, MajorLink } from "../components/nav"
import { ImageCard, Rows, Hr, ResponsiveSplit } from "../components/atoms"
import { Fragment, useEffect } from "react"
import {
  MovementLogo,
  WireLogo,
  BlueprintLogo,
  ObservatoryLogo,
} from "../images/pillars"
import { useTranslation, Trans } from "react-i18next"
import HomepageBanner from "../components/homepage-banner"
import HomepageCarousel from "../components/homepage-carousel"
import { useTextDirection } from "../i18n"
import { InternationalistStickerStatic } from "../components/stickers"
import useTiles from "../hooks/useTiles"

const IndexPage: PageRoot<{
  wire: Connection<ContentNode>
  movement: Connection<ContentNode>
  movementAnnouncements: Connection<ContentNode>
  tiles: Connection<ContentNode>
  blueprint: Connection<ContentNode>
  observatory: Connection<ContentNode>
  allPillars: Connection<ContentNode>
  file: { childImageSharp: SharpImage }
  custom: ContentNode
}> = ({
  data: {
    wire,
    movement,
    movementAnnouncements,
    tiles,
    blueprint,
    observatory,
    allPillars,
    file,
    custom,
  },
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const dir = useTextDirection(language)
  const latest = getOverallLatest(
    wire,
    movement,
    movementAnnouncements,
    blueprint,
    observatory,
    5
  )
  const tileList = useTiles(tiles.edges[0].node?.frontmatter.tiles)

  return (
    <Fragment>
      {custom.frontmatter?.vimeoId && custom.frontmatter?.placeholderImage ? (
        <HomepageBanner
          vimeoId={custom.frontmatter.vimeoId}
          placeholderImage={custom.frontmatter?.placeholderImage}
        />
      ) : custom.frontmatter?.headerImages?.length > 0 ? (
        <HomepageCarousel
          headerImages={custom.frontmatter.headerImages}
          defaultImage={file}
        />
      ) : (
        <div>
          <ImageCard
            sx={{
              height: "50%",
              minHeight: "100px",
              display: "flex",
            }}
            src={file.childImageSharp}
            childWrapperStyle={{ width: "100%", display: "flex" }}
          >
            <ContentBlock sx={{ alignContent: "end" }}>
              <Heading
                variant="headings.1"
                sx={{
                  width: ["null", null, "50%"],
                  mb: [4, null, 4],
                  mt: [8, null, 7],
                  color: "#FFFFFF",
                }}
              >
                <Trans>
                  We unite, organise, and mobilise progressive forces around the
                  world.
                </Trans>
              </Heading>

              <Link to={"/join"}>
                <Button
                  sx={{
                    width: ["100%", null, "auto"],
                    fontWeight: 600,
                    color: "#000",
                    backgroundColor: "#FC164B",
                    marginBottom: "30px",
                  }}
                  variant="primary"
                >
                  <Trans>Get Involved</Trans>
                </Button>
              </Link>
            </ContentBlock>
          </ImageCard>
        </div>
      )}

      <div sx={{ backgroundColor: "red", margin: "auto", height: "40px" }}>
        <Marquee
          pauseOnHover={true}
          gradient={false}
          speed={40}
          sx={{ height: "3rem" }}
        >
          {allPillars.edges.map(article => {
            const localisedContent = useLocalisedContent(article.node)
            return (
              <Fragment>
                <Link
                  to={localisedContent.path}
                  key={article.node.id}
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: "20px",
                    margin: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  {localisedContent.title}
                </Link>
                <p sx={{ position: "relative", marginTop: "12px" }}>●</p>
              </Fragment>
            )
          })}
        </Marquee>
      </div>

      <ContentBlock sx={{ pb: 0 }}>
        <BlockHeading sx={{ color: "latestHex" }}>
          <Trans>Latest</Trans>
        </BlockHeading>
      </ContentBlock>

      <ResponsiveSplit isRtl={dir.isRtl} sx={{ pt: 0 }}>
        <LatestPrimaryFeatured
          node={latest.edges[0].node}
          associatedDate={latest.edges[0].date}
        />

        <div>
          <FeaturedList sx={{ flex: 1, p: [4, null, 0], pb: 0 }}>
            {latest.edges.slice(1).map(({ node, date }) => (
              <Link key={node.id} to={node.fields.path}>
                <LatestFeaturedListItem node={node} associatedDate={date} />
              </Link>
            ))}
          </FeaturedList>
        </div>
      </ResponsiveSplit>

      <InstagramModule limit={10} bgColour={"#F7F4F1"} isRtl={dir.isRtl} />

      <Box sx={{ bg: "white", mt: [2, 2, 3], mb: [3, 4], py: [1, 2] }}>
        <ContentBlock>
          <Text sx={{ color: "darkGrey", fontFamily: "monospace", mb: [3, 2] }}>
            <Trans>Our activities are divided across four pillars:</Trans>
          </Text>
          <Grid columns={[1, null, 4]} gap={[4, 5, 6]} sx={{ my: 0 }}>
            <MajorLink
              to="/movement"
              icon={MovementLogo}
              color="movementHex"
              sx={{
                ":hover": { bg: "movementHexLight" },
                transition: "all 0.1s ease",
              }}
              title={t(`Movement`)}
            >
              <Trans>Empowering Progressive Activists &amp; Organisers</Trans>
            </MajorLink>
            <MajorLink
              to="/blueprint"
              icon={BlueprintLogo}
              color="blueprintHex"
              sx={{
                ":hover": { bg: "blueprintHexLight" },
                transition: "all 0.1s ease",
              }}
              title={t(`Blueprint`)}
            >
              <Trans>Crafting a Shared Vision of a World Transformed</Trans>
            </MajorLink>
            <MajorLink
              to="/wire"
              icon={WireLogo}
              color="wireHex"
              sx={{
                ":hover": { bg: "wireHexLight" },
                transition: "all 0.1s ease",
              }}
              title={t(`Wire`)}
            >
              <Trans>Publishing Grassroots &amp; Critical Perspectives</Trans>
            </MajorLink>
            <MajorLink
              to="/observatory"
              icon={ObservatoryLogo}
              color="observatoryHex"
              sx={{
                ":hover": { bg: "observatoryHexLight" },
                transition: "all 0.1s ease",
              }}
              title={t(`Observatory`)}
            >
              <Trans>Tracking Attacks on the Democratic Process</Trans>
            </MajorLink>
          </Grid>
        </ContentBlock>
      </Box>

      <ContentBlock sx={{ pb: 0 }}>
        <BlockHeading sx={{ color: "movementHex" }}>
          <Link to="/movement">
            <Trans>Movement</Trans>
          </Link>
        </BlockHeading>
      </ContentBlock>

      {tileList?.length > 0 && (
        <ResponsiveSplit isRtl={dir.isRtl} sx={{ pt: 0 }}>
          <div>
            <TileSplitter
              tileWrapper={tileList[0]}
              nodeComponent={
                <PrimaryFeatured node={tileList[0].tile.campaignArticle!} />
              }
              tileComponent={<TilePrimary tile={tileList[0].tile} />}
            />
          </div>

          <div>
            <FeaturedList
              sx={{
                flex: 1,
                p: [4, null, 0],
                pb: 0,
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {tileList.slice(1, 5).map(tileWrapper => (
                <TileSplitter
                  tileWrapper={tileWrapper}
                  nodeComponent={
                    <FeaturedListItem
                      node={tileWrapper.tile.campaignArticle!}
                      style={{ flex: "1 1 auto" }}
                    />
                  }
                  tileComponent={
                    <FeaturedListTile
                      tile={tileWrapper.tile}
                      themeColor={"movementHex"}
                      style={{ flex: "1 1 auto" }}
                    />
                  }
                />
              ))}
            </FeaturedList>
          </div>
        </ResponsiveSplit>
      )}

      <Box
        sx={{ bg: "blueprintHexLight", mt: [4, 5, 6], mb: [3, 4], py: [3, 4] }}
      >
        <ContentBlock>
          <BlockHeading sx={{ color: "blueprintHex" }}>
            <Link to="/blueprint">
              <Trans>Blueprint</Trans>
            </Link>
          </BlockHeading>
          <Grid gap={[3, null, 7]} columns={[null, null, 2]}>
            {blueprint.edges.map(({ node }, i) =>
              i === 0 ? (
                <SecondaryFeaturedLarge key={node.id} node={node} />
              ) : (
                <SecondaryFeatured
                  key={node.id}
                  node={node}
                  dymaxion={i % 2 == 0}
                />
              )
            )}
          </Grid>
        </ContentBlock>
      </Box>

      <ContentBlock>
        <BlockHeading sx={{ color: "wireHex" }}>
          <Link to="/wire">
            <Trans>Wire</Trans>
          </Link>
        </BlockHeading>
        <Grid gap={[5, null, 7]} columns={[null, null, 3]}>
          {wire.edges.map(({ node }) => (
            <TertiaryFeatured key={node.id} node={node} truncated={false} />
          ))}
        </Grid>
      </ContentBlock>

      <Box
        sx={{
          bg: "observatoryHexLight",
          mt: [4, 5, 6],
          mb: [3, 4],
          py: [3, 4],
        }}
      >
        <ContentBlock>
          <BlockHeading sx={{ color: "observatoryHex" }}>
            <Link to="/observatory">
              <Trans>Observatory</Trans>
            </Link>
          </BlockHeading>
          {observatory.edges.map(({ node }) => (
            <LatestPrimaryFeatured node={node} />
          ))}
        </ContentBlock>
      </Box>

      {["en", "en-gb"].includes(language) && <InternationalistStickerStatic />}

      <div id="index-indicator"></div>
    </Fragment>
  )
}

export const pageQuery = graphql`
  query HomePage {
    file(relativePath: { eq: "web_header_map.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    custom: markdownRemark(frontmatter: { contentId: { eq: "index-page" } }) {
      frontmatter {
        headerImages {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link
          translations {
            abstract
            title
            locale
          }
        }
        vimeoId
        placeholderImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    movement: allMarkdownRemark(
      filter: {
        fields: { type: { in: ["movement-actions", "movement-campaigns"] } }
      }
      sort: { fields: [frontmatter___startDate], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
    movementAnnouncements: allMarkdownRemark(
      filter: { fields: { type: { eq: "movement-articles" } } }
      sort: { fields: [frontmatter___publishDate], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
    tiles: allMarkdownRemark(
      filter: { frontmatter: { contentId: { eq: "movement-tiles" } } }
    ) {
      edges {
        node {
          frontmatter {
            tiles {
              campaignArticle {
                ...ListFragment
              }
              translations {
                locale
                manualLink
                title
              }
              manualImage {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 50) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    blueprint: allMarkdownRemark(
      filter: { fields: { type: { eq: "blueprint-documents" } } }
      sort: { fields: [frontmatter___publishDate], order: DESC }
      limit: 2
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
    wire: allMarkdownRemark(
      filter: { fields: { type: { eq: "wire-articles" } } }
      sort: { fields: [frontmatter___publishDate], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
    observatory: allMarkdownRemark(
      filter: { fields: { type: { eq: "observatory-articles" } } }
      sort: { fields: [frontmatter___publishDate], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
    allPillars: allMarkdownRemark(
      filter: {
        fields: {
          type: {
            in: ["wire-articles", "blueprint-documents", "observatory-articles"]
          }
        }
      }
      sort: { fields: [frontmatter___publishDate], order: DESC }
      limit: 24
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
  }
`

export default IndexPage
