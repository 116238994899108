/** @jsx jsx */
import { graphql } from "gatsby"
import { ImageCard, ViewElement } from "./atoms"
import { Box, jsx } from "theme-ui"
import { Link } from "./nav"

export const InternationalistSticker: ViewElement<{}> = () => {
  return (
    <div
      sx={{
        position: "relative",
        margin: "auto",
        backgroundColor: "#000",
        maxWidth: "1000px",
        padding: "50px",
        borderRadius: "100px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      <div></div>
      <div></div>
    </div>
  )
}

const InternationalistImage = require("../images/internationalist_sticker.png")

export const InternationalistStickerStatic: ViewElement<{}> = () => {
  return (
    <Link
      to={"https://act.progressive.international/theinternationalist/#blank-3"}
    >
      <Box
        sx={{
          position: "relative",
          display: "block",
          height: ["40.5vw", "min(40vw, 560px)", "min(40vw, 560px)"],
          width: "90vw",
          margin: "0 auto",
          maxWidth: "1200px",
          maxHeight: "575px",
        }}
      >
        <ImageCard
          sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
          src={InternationalistImage}
        />
      </Box>
    </Link>
  )
}
