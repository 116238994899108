import { useEffect, useState } from "react"
import { Tile, TileWrapper } from "../types/movement"

const useTiles = (tiles: Tile[]) => {
  const processTiles = (input: Tile[]) => {
    return (
      input
        .map(tile => {
          if (
            tile.campaignArticle &&
            typeof tile.campaignArticle !== "string"
          ) {
            return {
              isManual: false,
              tile,
            }
          } else if (
            tile.translations &&
            tile.translations.length > 0 &&
            tile.manualImage
          ) {
            return {
              isManual: true,
              tile,
            }
          }
        })
        .filter((tileWrapper: TileWrapper | undefined) => !!tileWrapper) || []
    )
  }

  const [response, setResponse] = useState<TileWrapper[]>(processTiles(tiles))

  useEffect(() => {
    setResponse(processTiles(tiles))
  }, [tiles])

  return response
}

export default useTiles
