/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui"
import VimeoPlayer from "@u-wave/react-vimeo"
import { ImageCard } from "./atoms"
import { useState, useRef, useEffect } from "react"
import { Player } from "@vimeo/player"
import { useWindowSize } from "@react-hook/window-size/throttled"
import { analytics } from "../data/analytics"
import { graphql, useStaticQuery } from "gatsby"
import { File } from "../data/content"

const PLACEHOLDER_IMG_QUERY = graphql`
  query PlaceholderImageQuery {
    placeholderImage: file(relativePath: { eq: "video-snapshot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 75) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default ({ vimeoId, placeholderImage }: { vimeoId?: number, placeholderImage?: File }) => {
  const [videoState, setVideoState] = useState<
    "unloaded" | "loaded" | "paused" | "playing" | "ended"
  >("unloaded")
  const hasEnded = videoState === "ended"
  const canPlay = videoState !== "unloaded"
  const isPlaying = videoState === "playing"
  const isPaused = videoState === "paused"
  const isInProgress = !hasEnded && (isPaused || isPlaying)
  const [width, height] = useWindowSize()

  // Docs: https://developer.vimeo.com/player/sdk/reference#events-for-text-tracks
  const vimeoRef = useRef<Player>(null)

  const collectVimeoPlayer = player => {
    vimeoRef.current = player
  }

  useEffect(() => {
    vimeoRef.current?.on("play", () => setVideoState("playing"))
    vimeoRef.current?.on("pause", () => setVideoState("paused"))
    vimeoRef.current?.on("ended", () => {
      analytics.trackEvent({ category: "website", action: "finish-video" })
      setVideoState("ended")
    })

    return () => {
      vimeoRef.current?.off("play")
      vimeoRef.current?.off("pause")
      vimeoRef.current?.off("ended")
    }
  }, [vimeoRef.current])

  const requestPlay = () => {
    if (hasEnded) {
      vimeoRef.current?.setCurrentTime(0)
    }
    analytics.trackEvent({ category: "website", action: "watch-video" })
    vimeoRef.current?.play()
    window.scrollTo(0, 0)
  }

  const { defaultPlaceholderImage } = useStaticQuery(PLACEHOLDER_IMG_QUERY)

  return (
    <Box
      sx={{
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        minHeight: ["75vh", null, 600, 800],
        background: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!isInProgress && (
        <ImageCard
          src={placeholderImage ? placeholderImage.childImageSharp : defaultPlaceholderImage.childImageSharp}
          sx={{
            top: 0,
            left: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        />
      )}
      <VimeoPlayer
        video={vimeoId ? vimeoId : process.env.GATSBY_VIMEO_ID}
        // Controls
        onReady={collectVimeoPlayer}
        onLoaded={() => setVideoState("loaded")}
        // UI
        controls={false}
        showTitle={false}
        showByline={false}
        color={"FC164B"}
        // Layout
        responsive
        width={"100%"}
        style={{
          zIndex: 80,
          display: isInProgress ? "block" : "none",
          flexGrow: 1,
          flexShrink: 0,
          width: "100%",
          top: 0,
          left: 0,
        }}
      />
      {!isInProgress && (
        <Container sx={{ position: "absolute", bottom: 0, px: 2 }}>
          <Box
            sx={{
              cursor: "pointer",
              width: [50, null, 75],
              height: [50, null, 75],
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid white",
              borderRadius: 100,
              color: "white",
              zIndex: 50,
              position: "absolute",
              bottom: 0,
              mx: 3,
              my: [3, null, 4],
              fontSize: [5, null, 6],
              ":hover": {
                color: "text",
                borderColor: "text",
                background: "rgba(255,255,255,0.9)",
              },
            }}
            onClick={requestPlay}
          >
            <span sx={{ mr: -1 }}>▶</span>
          </Box>
        </Container>
      )}
    </Box>
  )
}
