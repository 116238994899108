/** @jsx jsx */

import { jsx, Spinner } from "theme-ui"
import { ViewElement } from "./atoms"
import { useState, useEffect, useRef } from "react"
import { ImageCard } from "../components/atoms"
import { graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { isUndefined } from "lodash"
import useThrottle from "../hooks/useThrottle"

const noImage = require("../images/noimage.png")

export class MediaData {
  id: string
  caption: string
  mediaType: string
  mediaUrl: string
  timestamp: string
  thumbnailUrl: string
  permalink: string

  constructor(
    id: string,
    caption: string,
    mediaType: string,
    mediaUrl: string,
    timestamp: string,
    thumbnail_url: string,
    permalink: string
  ) {
    this.id = id
    this.caption = caption
    this.mediaType = mediaType
    this.mediaUrl = mediaUrl
    this.timestamp = timestamp
    this.thumbnailUrl = thumbnail_url
    this.permalink = permalink
  }
}

export const InstagramModule: ViewElement<{
  limit: number
  bgColour: string
  isRtl: boolean
}> = ({ limit, bgColour, isRtl, ...props }) => {
  const sideLimit = 40

  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(true)
  const [instaScroller, setInstaScroller] = useState<HTMLElement | null>(null)
  const throttledFunction = useThrottle(handleScroll, 100)

  const [isLoaded, setIsLoaded] = useState(false)
  const [posts, setPosts] = useState<MediaData[]>([])

  const fadeColour = bgColour.replace("#", "")
  const red = parseInt(fadeColour.substring(0, 2), 16).toString()
  const green = parseInt(fadeColour.substring(2, 4), 16).toString()
  const blue = parseInt(fadeColour.substring(4, 6), 16).toString()

  const queryResponse = useStaticQuery(instaQuery)
  const offsetIncrement: number = 360

  function scrollLimit() {
    return instaScroller
      ? instaScroller.scrollWidth - instaScroller.clientWidth
      : 0
  }

  const getInstaFeedPosts = () => {
    try {
      return queryResponse.igDataJson.data.map(d => {
        return new MediaData(
          d.id,
          d.caption,
          d.media_type,
          d.media_url,
          d.timestamp,
          d.thumbnail_url,
          d.permalink
        )
      })
    } catch (e) {
      return "Error"
    }
  }

  function handleScroll() {
    if (!isUndefined(instaScroller?.scrollLeft)) {
      if (instaScroller.scrollLeft > 0 && !showLeftArrow) {
        setShowLeftArrow(true)
      } else if (instaScroller.scrollLeft <= 0 && showLeftArrow) {
        setShowLeftArrow(false)
      }

      if (instaScroller.scrollLeft < scrollLimit() && !showRightArrow) {
        setShowRightArrow(true)
      } else if (instaScroller.scrollLeft >= scrollLimit() && showRightArrow) {
        setShowRightArrow(false)
      }
    }
  }

  function manualScroll(input: number) {
    if (instaScroller) {
      instaScroller.scroll({
        top: 0,
        left: instaScroller.scrollLeft + input,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    const response = getInstaFeedPosts()
    if (response != "Error") {
      setPosts(response)
    }
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    if (!instaScroller) {
      const tempInstaScroller = document.getElementById("scrollerWrapper")
      setInstaScroller(tempInstaScroller)
    } else {
      handleScroll()
    }

    instaScroller?.addEventListener("scroll", throttledFunction)

    return () => {
      instaScroller?.removeEventListener("scroll", throttledFunction)
    }
  }, [instaScroller, showLeftArrow, showRightArrow, isLoaded])

  if (isLoaded && posts?.length > 0) {
    return (
      <div sx={{ position: "relative" }}>
        <div
          sx={{
            position: "relative",
            height: ["170px", "270px", "370px"],
            width: "100%",
            paddingX: "50px",
            whiteSpace: "nowrap",
            overflowX: "scroll",
            overflowY: "hidden",
            marginTop: ["0px", "0px", "15px"],
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
          id={"scrollerWrapper"}
          className={"hiddenScrollbar"}
        >
          {posts.map(p => (
            <InstagramPost mediaData={p} />
          ))}
        </div>
        <div
          sx={{
            position: "absolute",
            height: "100%",
            width: ["40px", "80px", "120px"],
            left: "0px",
            top: "0px",
            background:
              "linear-gradient(to right, rgba(" +
              red +
              ", " +
              green +
              ", " +
              blue +
              ", 1), rgba(" +
              red +
              ", " +
              green +
              ", " +
              blue +
              ", 0))",
          }}
        ></div>
        <div
          sx={{
            position: "absolute",
            height: "100%",
            width: ["40px", "80px", "120px"],
            right: "0px",
            top: "0px",
            background:
              "linear-gradient(to left, rgba(" +
              red +
              ", " +
              green +
              ", " +
              blue +
              ", 1), rgba(" +
              red +
              ", " +
              green +
              ", " +
              blue +
              ", 0))",
          }}
        ></div>
        {showLeftArrow && (
          <FontAwesomeIcon
            icon={faChevronLeft}
            sx={{
              position: "absolute",
              left: "0px",
              cursor: "pointer",
              top: "50%",
              transform: "translateY(-50%)",
              opacity: "0.2",
              width: "60px",
              height: "60px",
              ":hover": {
                opacity: "0.5",
              },
            }}
            onClick={() => manualScroll(-offsetIncrement)}
          />
        )}
        {showRightArrow && (
          <FontAwesomeIcon
            icon={faChevronRight}
            sx={{
              position: "absolute",
              right: "0px",
              cursor: "pointer",
              top: "50%",
              transform: "translateY(-50%)",
              opacity: "0.2",
              width: "60px",
              height: "60px",
              ":hover": {
                opacity: "0.5",
              },
            }}
            onClick={() => manualScroll(offsetIncrement)}
          />
        )}
      </div>
    )
  } else if (isLoaded) {
    return null
  } else {
    return (
      <div
        sx={{
          color: "#280000",
          height: ["60px", "120px", "180px"],
          width: "100%",
          paddingX: "10px",
          fontSize: ["10px", "15px", "20px"],
          lineHeight: ["60px", "120px", "180px"],
          textAlign: "center",
        }}
      >
        <Spinner />
      </div>
    )
  }
}

export const InstagramPost: ViewElement<{
  mediaData: MediaData
}> = ({ mediaData, ...props }) => {
  return (
    <a href={mediaData.permalink}>
      <div sx={{ display: "inline-block" }}>
        <ImageCard
          sx={{
            width: ["150px", "250px", "350px"],
            height: ["150px", "250px", "350px"],
            margin: "10px",
            backgroundColor: "accentTint",
          }}
          src={
            mediaData.mediaType == "VIDEO"
              ? mediaData.thumbnailUrl == ""
                ? noImage
                : mediaData.thumbnailUrl
              : mediaData.mediaUrl == ""
              ? noImage
              : mediaData.mediaUrl
          }
        />
      </div>
    </a>
  )
}

const instaQuery = graphql`
  query instaPosts {
    igDataJson(data: { elemMatch: {} }) {
      data {
        caption
        id
        media_type
        media_url
        permalink
        thumbnail_url
        timestamp
      }
    }
  }
`
